import React from 'react';

const TaskIcon = props => (
  <svg width={24} height={29} {...props}>
    <g strokeWidth={2} fill="none" fillRule="evenodd" strokeLinecap="square">
      <path stroke="#8FA4AE" d="M19.944 5H23v23H1V5h3.056" className="stroke" />
      <path stroke="#CFD8DC" d="M8 17.667L11.214 21 17 15" className="stroke" />
      <path
        className="stroke"
        d="M15.556 4.182C15.556 2.425 14.187 1 12.5 1S9.444 2.425 9.444 4.182H7V8h11V4.182h-2.444z"
        stroke="#8FA4AE"
      />
    </g>
  </svg>
);

export default TaskIcon;
