import React from 'react';

const MenuPrivateAgentsHover = props => (
  <svg width={23} height={25} {...props}>
    <defs>
      <linearGradient
        x1="18.877%"
        y1="0%"
        x2="75.734%"
        y2="100%"
        id="MenuPrivateAgentsHover_svg__a"
      >
        <stop stopColor="#0E50B4" offset="0%" />
        <stop stopColor="#009BFB" offset="100%" />
      </linearGradient>
    </defs>
    <g strokeWidth={2} fill="none" fillRule="evenodd">
      <path
        d="M7.136 14L1.02 17.474C.387 17.832 0 18.477 0 19.172V23h21v-3.828c0-.696-.387-1.34-1.02-1.698L13.864 14"
        stroke="url(#MenuPrivateAgentsHover_svg__a)"
        fill="#FFF"
        transform="translate(1 1)"
      />
      <path d="M14 19h3" stroke="#009FFF" strokeLinecap="square" />
      <path
        d="M4 6.429C4 2.878 6.91 0 10.5 0S17 2.878 17 6.429C17 11.786 14.09 15 10.5 15S4 11.786 4 6.429z"
        stroke="url(#MenuPrivateAgentsHover_svg__a)"
        fill="#FFF"
        strokeLinecap="square"
        transform="translate(1 1)"
      />
      <path
        d="M5 5.706A6.203 6.203 0 0 0 6.817 6c1.798 0 3.42-.767 4.585-2A6.32 6.32 0 0 0 16 6"
        stroke="url(#MenuPrivateAgentsHover_svg__a)"
        transform="translate(1 1)"
      />
    </g>
  </svg>
);

export default MenuPrivateAgentsHover;
