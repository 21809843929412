import React from 'react';
import { Loading } from '@radargovernamental/orbit-style';
import css from './ExpansiveLoading.module.scss';

const ExpansiveLoding = () => (
  <div className={css.expansiveLoading}>
    <Loading center primary lg />
  </div>
);

ExpansiveLoding.propTypes = {};

export default ExpansiveLoding;
