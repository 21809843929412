import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';

import { Text, Spacer } from '@radargovernamental/orbit-style';
import css from './UserActions.module.scss';

// UI
import Avatar from '../../Avatar/Avatar';

// Actions
import Logout from '../../../../lib/HOC/Logout';
import AuthInfo from '../../../../lib/HOC/AuthInfo';
import ChevronDown from '../../Icons/ChevronDown';

class UserActions extends Component {
  state = {
    focus: false,
  };

  render() {
    const { focus } = this.state;
    const { user, userIsAuthenticated, logout } = this.props;
    if (!userIsAuthenticated) {
      return null;
    }
    return (
      <div
        className={`${css.userActions} ${
          focus ? css['userActions--focused'] : ''
        }`}
        onFocus={() => this.setState({ focus: true })}
        onBlur={() => this.setState({ focus: false })}
      >
        <div style={{ flexShrink: 0 }}>
          <Avatar alt={user.fullName} src={user.avatar} />
        </div>
        <span className={css.userActions__name}>
          <Spacer
            mlLg={2}
            mlMd={2}
            mlSm={2}
            mlXs={2}
            inline
            style={{ flexShrink: 0 }}
          >
            <Text white inline>
              {user.firstName}
            </Text>
          </Spacer>
        </span>
        <Spacer mlLg={4} mlMd={4} mlSm={4} mlXs={4} inline>
          <ChevronDown />
        </Spacer>
        <ul className={css.userActions__dropdown}>
          {/* <li> */}
          {/* <Link to="/perfil" className={css.userActions__dropdown__item}>Meu Perfil</Link> */}
          {/* </li> */}
          {/* <li> */}
          {/* <Link to="/perfil" className={css.userActions__dropdown__item}>Segurança</Link> */}
          {/* </li> */}
          <li>
            <button
              type="button"
              onClick={() => logout()}
              className={css.userActions__dropdown__item}
            >
              Sair
            </button>
          </li>
        </ul>
      </div>
    );
  }
}

UserActions.propTypes = {
  logout: PropTypes.func.isRequired,
  user: PropTypes.object,
  userIsAuthenticated: PropTypes.bool,
};
UserActions.defaultProps = {
  user: null,
  userIsAuthenticated: false,
};

export default AuthInfo(Logout(UserActions));
