import React from 'react';

const Expand = props => (
  <svg width={32} height={11} {...props}>
    <g stroke="#CFD8DC" strokeWidth={2} fill="none" fillRule="evenodd">
      <path d="M20 5.5h10" />
      <path strokeLinecap="square" d="M26.5 2L30 5.5 26.5 9" />
      <path d="M12 5.5H2" />
      <path strokeLinecap="square" d="M5.5 2L2 5.5 5.5 9" />
    </g>
  </svg>
);

export default Expand;
