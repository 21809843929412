/* eslint-disable max-len */
import React from 'react';

const MenuAuthorsHover = props => (
  <svg width={28} height={27} {...props}>
    <defs>
      <linearGradient x1="18.877%" y1="0%" x2="75.734%" y2="100%" id="mas_a">
        <stop stopColor="#0E50B4" offset="0%" />
        <stop stopColor="#009BFB" offset="100%" />
      </linearGradient>
    </defs>
    <g strokeWidth={2} fill="none" fillRule="evenodd">
      <g stroke="url(#mas_a)" transform="translate(1 1)">
        <path d="M5.437 10.389L.777 12.94c-.482.263-.777.736-.777 1.247V17h16v-2.812c0-.511-.295-.985-.777-1.247l-4.66-2.552M3.765 4.583a9.323 9.323 0 0 0 1.555.14c1.538 0 2.925-.363 3.923-.945.997.582 2.396.944 3.933.944" />
        <path
          d="M3.765 4.857C3.765 2.174 5.87 0 8.47 0c2.599 0 4.705 2.174 4.705 4.857 0 4.048-2.106 6.476-4.705 6.476-2.6 0-4.706-2.428-4.706-6.476z"
          strokeLinecap="square"
        />
      </g>
      <path
        d="M12.136 16L6.02 19.474C5.387 19.832 5 20.477 5 21.172V25h21v-3.828c0-.696-.387-1.34-1.02-1.698L18.864 16"
        stroke="url(#mas_a)"
        fill="#FFF"
        transform="translate(1 1)"
      />
      <path d="M19 21h3" stroke="#009FFF" strokeLinecap="square" />
      <path
        d="M9 8.429C9 4.878 11.91 2 15.5 2S22 4.878 22 8.429C22 13.786 19.09 17 15.5 17S9 13.786 9 8.429z"
        stroke="url(#mas_a)"
        fill="#FFF"
        strokeLinecap="square"
        transform="translate(1 1)"
      />
      <path
        d="M10 7.706A6.203 6.203 0 0 0 11.817 8c1.798 0 3.42-.767 4.585-2A6.32 6.32 0 0 0 21 8"
        stroke="url(#mas_a)"
        transform="translate(1 1)"
      />
    </g>
  </svg>
);

export default MenuAuthorsHover;
