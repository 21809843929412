import React from 'react';

const MenuReport = props => (
  <svg width={31} height={22} {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        stroke="#8FA4AE"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3 3l10.5 9L24 3"
      />
      <text
        fontFamily="SFCompactDisplay-Bold, SF Compact Display"
        fontSize={14}
        fontStyle="condensed"
        fontWeight="bold"
        fill="#8FA4AE"
        transform="translate(1 1)"
      >
        <tspan x={18} y={19}>
          {'@'}
        </tspan>
      </text>
      <path
        stroke="#8FA4AE"
        strokeWidth={2}
        strokeLinecap="square"
        d="M1.5 1.5v16M2.5 1.5h21M1.5 18.5h18M25.5 1.5v9"
      />
    </g>
  </svg>
);

export default MenuReport;
