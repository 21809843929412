/* eslint-disable max-len */
import React from 'react';

const MenuCompanyHover = props => (
  <svg width={29} height={29} {...props}>
    <defs>
      <linearGradient
        x1="18.877%"
        y1="0%"
        x2="75.734%"
        y2="100%"
        id="MenuCompanyHover_svg__a"
      >
        <stop stopColor="#0E50B4" offset="0%" />
        <stop stopColor="#009BFB" offset="100%" />
      </linearGradient>
    </defs>
    <g strokeWidth={2} fill="none" fillRule="evenodd" strokeLinecap="square">
      <path
        stroke="url(#MenuCompanyHover_svg__a)"
        d="M19.8 9V2.7H7.2v9.9M11.7 27h3.544M13.5 0v2.7M0 16.2h11.7V27H0z"
        transform="translate(1 1)"
      />
      <path d="M5.5 20.8h2.7" stroke="#009FFF" />
      <path
        stroke="url(#MenuCompanyHover_svg__a)"
        d="M15.3 12.6H27V27H15.3z"
        transform="translate(1 1)"
      />
      <path
        d="M20.8 20.8h2.7M20.8 17.2h2.7M5.5 24.4h2.7M20.8 24.4h2.7"
        stroke="#009FFF"
      />
    </g>
  </svg>
);

export default MenuCompanyHover;
