/* eslint-disable max-len */
import React from 'react';

const MobileMenuToggle = props => (
  <svg width={16} height={12} {...props}>
    <g fillRule="nonzero" fill="#FFF">
      <path d="M8 12H1a1 1 0 0 1 0-2h7a1 1 0 0 1 0 2zM15 2H1a1 1 0 0 1 0-2h14a1 1 0 0 1 0 2zM15 7H1a1 1 0 0 1 0-2h14a1 1 0 0 1 0 2z" />
    </g>
  </svg>
);

export default MobileMenuToggle;
