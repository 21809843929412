import { constants } from './actions';

const DEFAULT_STATE = {
  user: null,
  token: null,
  lastSyncAt: null,
};

/**
 * User sign in
 * @param user    User info
 * @param token   Auth token
 * @returns {{user: *, token: *, lastSyncAt: Date}}
 */
const login = ({ user, token }) => {
  return { user, token, lastSyncAt: new Date() };
};

/**
 * User logout, returning default state
 * @returns {{user: null, token: null, lastSyncAt: null}}
 */
const logout = () => {
  return DEFAULT_STATE;
};

/**
 * Reducer map
 * @param state
 * @param action
 * @returns {*}
 */
const auth = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case constants.LOGIN:
      return login(action.payload);
    case constants.LOGOUT:
      return logout();
    default:
      return state;
  }
};

export default auth;
