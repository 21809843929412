import React from 'react';

const MenuSearch = props => (
  <svg width={24} height={32} {...props}>
    <g
      transform="translate(1 1)"
      strokeWidth={2}
      fill="none"
      fillRule="evenodd"
    >
      <path stroke="#8FA4AE" strokeLinecap="square" d="M22 22H0V0h8l3 4h11z" />
      <path d="M12 14l4 4" stroke="#CFD8DC" />
      <circle
        stroke="#CFD8DC"
        strokeLinecap="square"
        cx={9.5}
        cy={11.5}
        r={3.5}
      />
    </g>
  </svg>
);

export default MenuSearch;
