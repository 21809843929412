/* eslint-disable max-len */
import React from 'react';

const MenuDashboard = props => (
  <svg width={26} height={26} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M-3-2h32v32H-3z" />
      <g fillRule="nonzero">
        <path d="M25.99 26H.01v-2h25.98v2z" fill="#8FA4AE" className="fill" />
        <path fill="#CFD8DC" className="fill" d="M2.036 18.051h2v5.941h-2z" />
        <path fill="#8FA4AE" className="fill" d="M6 16.285h2v7.707H6z" />
        <path fill="#CFD8DC" className="fill" d="M10 15.473h2v8.519h-2z" />
        <path fill="#8FA4AE" className="fill" d="M13.978 12h2v11.992h-2z" />
        <path fill="#CFD8DC" className="fill" d="M18 8.578h2v15.414h-2z" />
        <path fill="#8FA4AE" className="fill" d="M22 6h2v17.977h-2z" />
        <path
          d="M1.184 16.115l4.401-3.574a.497.497 0 0 1 .393-.105l4.082.665 3.728-5.7.496-.76a.498.498 0 0 1 .332-.217l4.238-.733 4.287-4.535.723.685-4.401 4.655a.498.498 0 0 1-.277.148l-4.188.725-4.282 6.548a.498.498 0 0 1-.496.219l-4.181-.68-4.228 3.433-.627-.774z"
          className="fill stroke"
          stroke="#8FA4AE"
          strokeWidth={0.5}
          fill="#8FA4AE"
        />
      </g>
    </g>
  </svg>
);

export default MenuDashboard;
