/* eslint-disable max-len */
import React from 'react';

const MenuCompany = props => (
  <svg width={29} height={29} {...props}>
    <g strokeWidth={2} fill="none" fillRule="evenodd" strokeLinecap="square">
      <path
        stroke="#8FA4AE"
        className="stroke"
        d="M20.8 10V3.7H8.2v9.9M12.7 28h3.544M14.5 1v2.7M1 17.2h11.7V28H1z"
      />
      <path d="M5.5 20.8h2.7" className="stroke" stroke="#CFD8DC" />
      <path stroke="#8FA4AE" className="stroke" d="M16.3 13.6H28V28H16.3z" />
      <path
        d="M20.8 20.8h2.7M20.8 17.2h2.7M5.5 24.4h2.7M20.8 24.4h2.7"
        stroke="#CFD8DC"
        className="stroke"
      />
    </g>
  </svg>
);

export default MenuCompany;
