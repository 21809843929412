import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.scss';
import 'react-tippy/dist/tippy.css';
import 'core-js/stable';
import App from './App';
import * as registerServiceWorker from './registerServiceWorker';

window.localStorage.debug =
  process.env.NODE_ENV !== 'production' ? 'BOOT,FORM,DOCUMENTS,debug' : null;

const root = createRoot(document.getElementById('root'));

root.render(<App />);
// registerServiceWorker();
registerServiceWorker.register();

