import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

export default WrappedComponent => {
  const AuthInfo = props => {
    const { auth, ...otherProps } = props;
    const { user, token } = auth;

    const userIsAuthenticated = !!user;
    const userCompaniesIds = userIsAuthenticated
      ? (user.companiesWithAccess || []).map(i => i._id)
      : [];

    return (
      <WrappedComponent
        userIsAuthenticated={userIsAuthenticated}
        userCompaniesIds={userCompaniesIds}
        authToken={token}
        user={user}
        {...otherProps}
      />
    );
  };

  AuthInfo.propTypes = {
    auth: PropTypes.object,
  };
  AuthInfo.defaultProps = {
    auth: {},
  };

  return connect(state => ({ auth: state.auth || {} }))(AuthInfo);
};
