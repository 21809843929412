import React from 'react';

const SvgIndexingIcon = props => (
  <svg width={22} height={24} {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="#8FA4AE" fillRule="nonzero" d="M15 1v6h6z" />
      <path stroke="#8FA4AE" strokeWidth={2} d="M15 1v6h6" className="stroke" />
      <path
        stroke="#8FA4AE"
        strokeWidth={2}
        strokeLinecap="square"
        d="M15 1H1v22h20V7z"
        className="stroke"
      />
      <path
        d="M6.008 17H4.734v-5.999h1.274V17zm2.338 0H7.15v-5.999h1.041l2.558 3.753h.052v-3.753h1.196V17H10.96l-2.562-3.784h-.053V17zm4.794-5.999h2.246c1.837 0 2.69 1.108 2.69 2.98 0 1.885-.867 3.019-2.69 3.019H13.14v-5.999zm1.275 1.064v3.867h.786c1.086 0 1.569-.637 1.569-1.92 0-1.288-.488-1.947-1.569-1.947h-.786z"
        fill="#8FA4AE"
        className="stroke"
      />
    </g>
  </svg>
);

export default SvgIndexingIcon;
