import React from 'react';
import get from 'lodash/get';
import { Link } from 'react-router-dom';

import RgLogo from '../../Logo/RgLogo';

import css from './CompanyLogo.module.scss';

const CompanyLogo = props => {
  const { company } = props;
  const companyUrl = get(company, ['images', 'logo', 'url']);
  const companyName = get(company, ['name']);
  const companyId = get(company, ['_id']);

  function renderRGLogo() {
    return (
      <Link to="/">
        <RgLogo showText={false} iconColor="#fff" width="40px" height="30px" />
      </Link>
    );
  }

  function renderCompanyLogo() {
    return (
      <div className={css.companyLogo}>
        <img src={companyUrl} alt={companyName} />
      </div>
    );
  }

  if (companyId === 'JHvZXP3imD6G33zii') return renderRGLogo();
  return company && companyUrl ? renderCompanyLogo() : renderRGLogo();
};

export default CompanyLogo;
