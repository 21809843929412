import React from 'react';

const SvgIndexingIconHover = props => (
  <svg width={22} height={24} {...props}>
    <defs>
      <linearGradient
        x1="50%"
        y1="0%"
        x2="50%"
        y2="100%"
        id="indexing-icon-hover_svg__a"
      >
        <stop stopColor="#2088DB" offset="0%" />
        <stop stopColor="#0E50B4" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="19.547%"
        y1="0%"
        x2="100%"
        y2="100%"
        id="indexing-icon-hover_svg__b"
      >
        <stop stopColor="#2088DB" offset="0%" />
        <stop stopColor="#0E50B4" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="39.218%"
        y1="50%"
        x2="93.031%"
        y2="198.022%"
        id="indexing-icon-hover_svg__c"
      >
        <stop stopColor="#2088DB" offset="0%" />
        <stop stopColor="#0E50B4" offset="100%" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path d="M-5-4h32v32H-5z" />
      <path
        fill="url(#indexing-icon-hover_svg__a)"
        fillRule="nonzero"
        d="M14 0v6h6z"
        transform="translate(1 1)"
      />
      <path
        stroke="url(#indexing-icon-hover_svg__a)"
        strokeWidth={2}
        d="M14 0v6h6"
        transform="translate(1 1)"
      />
      <path
        stroke="url(#indexing-icon-hover_svg__b)"
        strokeWidth={2}
        strokeLinecap="square"
        d="M14 0H0v22h20V6z"
        transform="translate(1 1)"
      />
      <path
        d="M5.008 16H3.734v-5.999h1.274V16zm2.338 0H6.15v-5.999h1.041l2.558 3.753h.052v-3.753h1.196V16H9.96l-2.562-3.784h-.053V16zm4.794-5.999h2.246c1.837 0 2.69 1.108 2.69 2.98 0 1.885-.867 3.019-2.69 3.019H12.14v-5.999zm1.275 1.064v3.867h.786c1.086 0 1.569-.637 1.569-1.92 0-1.288-.488-1.947-1.569-1.947h-.786z"
        fill="url(#indexing-icon-hover_svg__c)"
        transform="translate(1 1)"
      />
    </g>
  </svg>
);

export default SvgIndexingIconHover;
