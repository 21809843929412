export const operationConstants = {
  acessControl: {
    path: '/controle-de-acessos',
    auth: {
      operation: 'READ',
      resource: 'iam:users',
    },
    users: {
      search: {
        path: '/controle-de-acessos/usuarios/busca',
        auth: {
          operation: 'READ',
          resource: 'iam:navBar',
        },
      },
      new: {
        path: '/controle-de-acessos/usuarios/adicionar',
        auth: {
          operation: 'CREATE',
          resource: 'iam:users',
        },
      },
      edit: {
        auth: {
          operation: 'UPDATE',
          resource: 'iam:users',
        },
      },
      profile: {
        path: '/controle-de-acessos/usuarios/perfil',
      },
      parameters: {
        auth: {
          operation: 'READ',
          resource: 'iam:userParameters',
        },
      },
      dossie: {
        auth: {
          operation: 'READ',
          resource: 'iam:userDossier',
        },
      }
    },
    userTypes: {
      search: {
        path: '/controle-de-acessos/tipos-de-usuarios/busca',
        auth: {
          operation: 'READ',
          resource: 'iam:userTypes',
        },
      },
      new: {
        path: '/controle-de-acessos/tipos-de-usuarios/adicionar',
        auth: {
          operation: 'CREATE',
          resource: 'iam:userTypes',
        },
      },
      edit: {
        path: '/controle-de-acessos/tipos-de-usuarios/editar',
        auth: {
          operation: 'UPDATE',
          resource: 'iam:userTypes',
        },
      },
    },
    decisionTable: {
      path: '/controle-de-acessos/tabela-de-decisoes',
      auth: {
        operation: 'UPDATE',
        resource: 'iam:decisionTable',
      },
    },
    companyParameters: {
      auth: {
        operation: 'READ',
        resource: 'iam:companyParameters',
      }
    }
  },
  alerts: {
    path: '/alertas',
    auth: {
      operation: 'READ',
      resource: 'documents:alerts',
    },
    search: {
      path: '/alertas/busca',
      auth: {
        operation: 'READ',
        resource: 'documents:alerts',
      },
    },
  },
  auth: {
    login: { path: '/' },
    registerPassword: {
      path: '/registrar-senha/:token?',
    },
  },
  authors: {
    path: '/parlamentares',
    oldPath: '/authors',
    auth: {
      operation: "READ",
      resource: "authors:author"
    },
    notes: {
      auth: {
        operation: "READ",
        resource: "authors:notes"
      },
    },
    search: { path: '/parlamentares/busca' },
    profile: {
      path: '/parlamentares/perfil',
    },
    details: {
      oldPath: '/parlamentares/details',
      path: '/parlamentares/detalhes',
    },
    edit: {
      path: '/parlamentares/detalhes-avancados',
      auth: {
        operation: "UPDATE",
        resource: "authors:author"
      }
    },
    new: { 
      path: '/parlamentares/adicionar', 
      auth: {
        operation: "CREATE",
        resource: "authors:author"
      } 
    },
  },
  companies: {
    path: '/empresas',
    auth: {
      operation: "READ",
      resource: "companies:company"
    },
    search: { 
      path: '/empresas/busca',
      auth: {
        operation: "READ",
        resource: "companies:company"
      }  
    },
    new: { 
      path: '/empresas/adicionar', 
      auth: {
        operation: "CREATE",
        resource: "companies:company"
      }  
    },
    profile: {
      path: '/empresas/detalhes',
      auth: {
        operation: "UPDATE",
        resource: "companies:company"
      }
    },
  },
  dash: {
    path: '/dashboard',
    auth: {
      operation: "READ",
      resource: "reports:dashboard"
    }
  },
  documentIndexing: {
    path: '/indexacoes',
    auth: {
      operation: 'READ',
      resource: 'documentsIndexing:documentIndexing',
    },
    details: {
      path: '/indexacoes/detalhes',
      auth: {
        operation: 'UPDATE',
        resource: 'documentsIndexing:documentIndexing',
      },
    },
    new: { 
      path: '/indexacoes/adicionar', 
      auth: {
        operation: 'CREATE',
        resource: 'documentsIndexing:documentIndexing',
      },
    },
    search: { 
      path: '/indexacoes/busca', 
      auth: {
        operation: 'READ',
        resource: 'documentsIndexing:documentIndexing',
      },
    },
  },
  documents: {
    path: '/documentos',
    auth: {
      operation: 'READ',
      resource: 'documents:document',
    },
    oldPath: '/documents',
    search: {
      path: '/documentos/busca',
      oldPath: '/documentos/search',
      auth: {
        operation: 'READ',
        resource: 'documents:documentSearchResults',
      },
    },
    new: {
      path: '/documentos/novo',
      oldPath: '/documentos/new',
      auth: {
        operation: 'CREATE',
        resource: 'documents:document',
      },
    },
    details: {
      path: '/documentos/detalhes',
      oldPath: '/documentos/details',
      auth: {
        operation: 'READ',
        resource: 'documents:document',
      },
    },
    edit: {
      path: '/documentos/editar',
      oldPath: '/documentos/approve',
      auth: {
        operation: 'UPDATE',
        resource: 'documents:document',
      },
    },
    operationalMessages: {
      auth: {
        operation: 'READ',
        resource: 'documents:operationalMessages',
      },
    },
    userEngagement: {
      auth: {
        operation: 'READ',
        resource: 'documents:userEngagement',
      },
    },
    notes: {
      auth: {
        operation: 'READ',
        resource: 'documents:notes',
      },
    },
    documentAttachment: {
      auth: {
        operation: "READ",
        resource: "documents:documentAttachment"
      }
    },
    userEngagementEngagementStrategy:  {
      auth: {
        operation: "UPDATE",
        resource: "documents:userEngagementEngagementStrategy"
      }
    },
    userEngagementLegalImpact: {
      auth: {
        operation: "UPDATE",
        resource: "documents:userEngagementLegalImpact"
      }
    },
    userEngagementEngagementStrategyPosition: {
      auth: {
        operation: "UPDATE",
        resource: "documents:userEngagementEngagementStrategyPosition"
      }
    },
    userEngagementLegalImpactPosition: {
      auth: {
        operation: "UPDATE",
        resource: 'documents:userEngagementLegalImpactPosition'
      }
    },
    createLinks: {
      auth: {
        operation: "CREATE",
        resource: 'documents:createLinks'
      }
    },
    documentUpdate: {
      auth: {
        operation: "READ",
        resource: "documents:documentUpdate"
      }
    }
  },
  globalSearch: {
    path: '/busca',
  },
  legislativeAssembly: {
    path: '/casas-legislativas',
    auth: {
      operation: 'READ',
      resource: 'legislativeHouses:legislativeHouse',
    },
    profile: {
      path: '/casas-legislativas/perfil',
      auth: {
        operation: 'READ',
        resource: 'legislativeHouses:legislativeHouse',
      },
    },
  },
  links: {
    path: '/links',
    auth: {
      operation: 'READ',
      resource: 'documents:viewLinks',
    },
    search: {
      path: '/links/busca',
      auth: {
        operation: 'READ',
        resource: 'documents:viewLinks',
      },
    },
    details: {
      path: '/links/detalhes',
      oldPath: '/links/details',
      auth: {
        operation: 'READ',
        resource: 'documents:viewLinks',
      },
    },
  },
  organizations: {
    path: '/organizacoes',
    search: {
      path: '/organizacoes/busca',
      auth: {
        operation: 'READ',
        resource: 'organizations:organization',
      },
    },
    new: { 
      path: '/organizacoes/adicionar',
      auth: {
        operation: 'CREATE',
        resource: 'organizations:organization',
      },
    },
    edit: {
      path: '/organizacoes/detalhes',
      auth: {
        operation: 'UPDATE',
        resource: 'organizations:organization',
      },
    },
    delete: {
      auth: {
        operation: 'DELETE',
        resource: 'organizations:organization',
      },
    },
  },
  privateAgent: {
    path: '/agentes-privados',
    auth: {
      operation: 'READ',
      resource: 'privateAgents:privateAgent',
    },
    search: { 
      path: '/agentes-privados/busca', 
      auth: {
        operation: 'READ',
        resource: 'privateAgents:privateAgent',
      },
    },
    details: {
      path: '/agentes-privados/informacoes',
      auth: {
        operation: 'READ',
        resource: 'privateAgents:privateAgent',
      },
    },
    new: { 
      path: '/agentes-privados/adicionar', 
      auth: {
        operation: 'CREATE',
        resource: 'privateAgents:privateAgent',
      },
    },
    edit: {
      path: '/agentes-privados/detalhes',
      auth: {
        operation: 'UPDATE',
        resource: 'privateAgents:privateAgent',
      },
    },
    delete: {
      auth: {
        operation: 'DELETE',
        resource: 'privateAgents:privateAgent',
      },
    },
  },
  radares: {
    path: '/radares',
    auth: {
      operation: 'READ',
      resource: 'monitoring:radar',
    },
    search: {
      path: '/radares/busca',
      auth: {
        operation: 'READ',
        resource: 'monitoring:radar',
      },
    },
    profile: {
      path: '/radares/perfil',
      auth: {
        operation: 'READ',
        resource: 'monitoring:radar',
      },
    },
  },
  reports: {
    path: '/relatorios',
    search: {
      path: '/relatorios/busca',
      auth: {
        operation: 'READ',
        resource: 'reports:clientReports',
      },
    },
    new: {
      path: '/relatorios/adicionar',
      auth: {
        operation: 'CREATE',
        resource: 'reports:clientReports',
      },
    },
    details: {
      path: '/relatorios/detalhes',
      auth: {
        operation: 'READ',
        resource: 'reports:clientReports',
      },
    },
    dashboard: {
      auth: {
        operation: 'READ',
        resource: 'reports:dashboard',
      },
    },
    edit: {
      auth: {
        operation: 'UPDATE',
        resource: 'reports:clientReports',
      },
    },
  },
  specialReports: {
    path: '/rg-analise',
    auth: {
      operation: 'READ',
      resource: 'panel:operationalTasksToday',
    },
  },
  speeches: {
    path: '/discursos',
    auth: {
      operation: 'READ',
      resource: 'speeches:speech',
    },
    search: {
      path: '/discursos/busca',
      auth: {
        operation: 'READ',
        resource: 'speeches:speech',
      },
    },
    details: {
      path: '/discursos/detalhes',
      auth: {
        operation: 'READ',
        resource: 'speeches:speech',
      },
    },
  },
  taskPanel: {
    path: '/painel',
    auth: {
      operation: 'READ',
      resource: 'panel:operationalTasksToday',
    },
    managementTasks: {
      auth: {
        operation: 'UPDATE',
        resource: 'panel:ManagementTasks',
      },
    }
  },
};
