import React, { Component } from 'react';
import PropTypes from 'prop-types';
import className from 'classnames';
import { withRouter, Link } from 'react-router-dom';
import { IAM } from 'react-rg-iam';

import css from './NavBar.module.scss';
import MenuNewDocument from '../Icons/MenuNewDocument';
import MenuNewDocumentHover from '../Icons/MenuNewDocumentHover';
import MenuSearch from '../Icons/MenuSearch';
import MenuSearchHover from '../Icons/MenuSearchHover';
import MenuLinks from '../Icons/MenuLinks';
import MenuLinksHover from '../Icons/MenuLinksHover';
import MenuUsers from '../Icons/MenuUsers';
import MenuUsersHover from '../Icons/MenuUsersHover';
import MenuAlerts from '../Icons/MenuAlerts';
import MenuAlertsHover from '../Icons/MenuAlertsHover';
import MenuDashboard from '../Icons/MenuDashboard';
import MenuDashboardHover from '../Icons/MenuDashboardHover';
import MenuRadar from '../Icons/MenuRadar';
import MenuRadarHover from '../Icons/MenuRadarHover';
import MenuSpeeches from '../Icons/MenuSpeeches';
import MenuSpeechesHover from '../Icons/MenuSpeechesHover';
import MenuAuthors from '../Icons/MenuAuthors';
import MenuAuthorsHover from '../Icons/MenuAuthorsHover';
import MenuTask from '../Icons/MenuTask';
import MenuTaskHover from '../Icons/MenuTaskHover';

import MenuDocumentIndexing from '../Icons/MenuDocumentIndexing';
import MenuDocumentIndexingHover from '../Icons/MenuDocumentIndexingHover';

import AuthInfo from '../../../lib/HOC/AuthInfo';
import ExpandIcon from '../Icons/Expand';
import MenuCompany from '../Icons/MenuCompany';
import MenuCompanyHover from '../Icons/MenuCompanyHover';

import MenuPrivateAgents from '../Icons/MenuPrivateAgents';
import MenuPrivateAgentsHover from '../Icons/MenuPrivateAgentsHover';

import MenuReport from '../Icons/MenuReport';
import MenuReportHover from '../Icons/MenuReportHover';

import { operationConstants} from '../../../utils/operationConstants';

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { onToggleMenu } = this.props;
    onToggleMenu(false);
  }

  render() {
    const {
      expanded,
      location,
      user,
      userCompaniesIds,
      visible,
      onToggleMenu,
    } = this.props;

    const classes = className({
      [css.navBar]: true,
      [css['navBar--expanded']]: expanded,
      [css['navBar--visible']]: visible,
    });

    const { pathname } = location;

    const isLinkActive = route =>
      new RegExp(`${route}((?=\\b)(?![\\/\\-])|[\\/\\w]+)`, 'g').test(pathname);

    return (
      <aside className={classes}>
        <nav>
          <ul className={css.navBar__list}>
            <IAM
              authority={user._id}
              companies={userCompaniesIds}
              resource="reports:clientReports"
              operation="READ"
            >
              <li>
                <Link
                  to={operationConstants.reports.path}
                  title="Relatórios"
                  className={
                    isLinkActive(operationConstants.reports.path) ? css.active : ''
                  }
                  onClick={this.handleClick}
                >
                  <span className={css.navBar__list__icon}>
                    <MenuReport className={css.iconNormal} />
                    <MenuReportHover className={css.iconHover} />
                  </span>
                  <span className={css.navBar__list__text}>Relatórios</span>
                </Link>
              </li>
            </IAM>
            <IAM
              authority={user._id}
              companies={userCompaniesIds}
              resource="panel:operationalTasksToday"
              operation="READ"
            >
              <li>
                <Link
                  to={operationConstants.taskPanel.path}
                  title="Tarefas"
                  className={
                    isLinkActive(operationConstants.taskPanel.path)
                      ? css.active
                      : ''
                  }
                  onClick={this.handleClick}
                >
                  <span className={css.navBar__list__icon}>
                    <MenuTask className={css.iconNormal} />
                    <MenuTaskHover className={css.iconHover} />
                  </span>
                  <span className={css.navBar__list__text}>
                    Painel de Tarefas
                  </span>
                </Link>
              </li>
            </IAM>
            <IAM
              authority={user._id}
              companies={userCompaniesIds}
              resource="documents:alerts"
              operation="READ"
            >
              <li>
                <Link
                  to={operationConstants.alerts.search.path}
                  title="Alertas"
                  className={
                    isLinkActive(operationConstants.alerts.search.path)
                      ? css.active
                      : ''
                  }
                  onClick={this.handleClick}
                >
                  <span className={css.navBar__list__icon}>
                    <MenuAlerts className={css.iconNormal} />
                    <MenuAlertsHover className={css.iconHover} />
                  </span>
                  <span className={css.navBar__list__text}>Alertas</span>
                </Link>
              </li>
            </IAM>
            <IAM
              authority={user._id}
              companies={userCompaniesIds}
              resource="documents:document"
              operation="CREATE"
            >
              <li>
                <Link
                  to={operationConstants.documents.new.path}
                  title="Novo Documento"
                  className={
                    isLinkActive(operationConstants.documents.new.path)
                      ? css.active
                      : ''
                  }
                  onClick={this.handleClick}
                >
                  <span className={css.navBar__list__icon}>
                    <MenuNewDocument className={css.iconNormal} />
                    <MenuNewDocumentHover className={css.iconHover} />
                  </span>
                  <span className={css.navBar__list__text}>Novo Documento</span>
                </Link>
              </li>
            </IAM>
            <IAM
              authority={user._id}
              companies={userCompaniesIds}
              resource="documentsIndexing:documentIndexing"
              operation="CREATE"
            >
              <li>
                <Link
                  to={operationConstants.documentIndexing.path}
                  title="Indexação"
                  className={
                    isLinkActive(operationConstants.documentIndexing.search.path)
                      ? css.active
                      : ''
                  }
                  onClick={this.handleClick}
                >
                  <span className={css.navBar__list__icon}>
                    <MenuDocumentIndexing className={css.iconNormal} />
                    <MenuDocumentIndexingHover className={css.iconHover} />
                  </span>
                  <span className={css.navBar__list__text}>Indexação</span>
                </Link>
              </li>
            </IAM>
            <IAM
              authority={user._id}
              companies={userCompaniesIds}
              resource="documents:documentSearchResults"
              operation="READ"
            >
              <li>
                <Link
                  to={operationConstants.documents.search.path}
                  title="Busca Geral"
                  className={
                    isLinkActive(operationConstants.documents.search.path)
                      ? css.active
                      : ''
                  }
                  onClick={this.handleClick}
                >
                  <span className={css.navBar__list__icon}>
                    <MenuSearch className={css.iconNormal} />
                    <MenuSearchHover className={css.iconHover} />
                  </span>
                  <span className={css.navBar__list__text}>Busca Geral</span>
                </Link>
              </li>
            </IAM>
            <IAM
              authority={user._id}
              companies={userCompaniesIds}
              resource="monitoring:radar"
              operation="READ"
            >
              <li>
                <Link
                  to={operationConstants.radares.path}
                  title="Meus Radares"
                  className={
                    isLinkActive(operationConstants.radares.path) ? css.active : ''
                  }
                  onClick={this.handleClick}
                >
                  <span className={css.navBar__list__icon}>
                    <MenuRadar className={css.iconNormal} />
                    <MenuRadarHover className={css.iconHover} />
                  </span>
                  <span className={css.navBar__list__text}>Meus Radares</span>
                </Link>
              </li>
            </IAM>
            <IAM
              authority={user._id}
              companies={userCompaniesIds}
              resource="authors:author"
              operation="READ"
            >
              <li>
                <Link
                  to={operationConstants.authors.search.path}
                  title="Parlamentares"
                  className={
                    isLinkActive(operationConstants.authors.path) ? css.active : ''
                  }
                  onClick={this.handleClick}
                >
                  <span className={css.navBar__list__icon}>
                    <MenuAuthors className={css.iconNormal} />
                    <MenuAuthorsHover className={css.iconHover} />
                  </span>
                  <span className={css.navBar__list__text}>Parlamentares</span>
                </Link>
              </li>
            </IAM>
            <IAM
              authority={user._id}
              companies={userCompaniesIds}
              resource="privateAgents:privateAgent"
              operation="READ"
            >
              <li>
                <Link
                  to={operationConstants.privateAgent.path}
                  title="Agentes Privados"
                  className={
                    isLinkActive(operationConstants.privateAgent.path)
                      ? css.active
                      : ''
                  }
                  onClick={this.handleClick}
                >
                  <span className={css.navBar__list__icon}>
                    <MenuPrivateAgents className={css.iconNormal} />
                    <MenuPrivateAgentsHover className={css.iconHover} />
                  </span>
                  <span className={css.navBar__list__text}>
                    Agentes Privados
                  </span>
                </Link>
              </li>
            </IAM>
            {/* <IAM
              authority={user._id}
              companies={userCompaniesIds}
              resource="organizations:organization"
              operation="READ"
            >
              <li>
                <Link
                  to={operationConstants.organizations.path}
                  title="Organizações"
                  className={isLinkActive(operationConstants.organizations.path) ? css.active : ''}
                  onClick={this.handleClick}
                >
                  <span className={css.navBar__list__icon}>
                    <MenuOrganization className={css.iconNormal} />
                    <MenuOrganizationHover className={css.iconHover} />
                  </span>
                  <span className={css.navBar__list__text}>Organizações</span>
                </Link>
              </li>
            </IAM> */}
            <IAM
              authority={user._id}
              companies={userCompaniesIds}
              resource="companies:company"
              operation="READ"
            >
              <li>
                <Link
                  to={operationConstants.companies.search.path}
                  title="Empresas"
                  className={
                    isLinkActive(operationConstants.companies.search.path)
                      ? css.active
                      : ''
                  }
                  onClick={this.handleClick}
                >
                  <span className={css.navBar__list__icon}>
                    <MenuCompany className={css.iconNormal} />
                    <MenuCompanyHover className={css.iconHover} />
                  </span>
                  <span className={css.navBar__list__text}>Empresas</span>
                </Link>
              </li>
            </IAM>
            <IAM
              authority={user._id}
              companies={userCompaniesIds}
              resource="documents:viewLinks"
              operation="READ"
            >
              <li>
                <Link
                  to={operationConstants.links.search.path}
                  title="Links Gerados"
                  className={
                    isLinkActive(operationConstants.links.search.path)
                      ? css.active
                      : ''
                  }
                  onClick={this.handleClick}
                >
                  <span className={css.navBar__list__icon}>
                    <MenuLinks className={css.iconNormal} />
                    <MenuLinksHover className={css.iconHover} />
                  </span>
                  <span className={css.navBar__list__text}>Links Gerados</span>
                </Link>
              </li>
            </IAM>
            <IAM
              authority={user._id}
              companies={userCompaniesIds}
              resource="iam:navBar"
              operation="READ"
            >
              <li>
                <Link
                  to={operationConstants.acessControl.path}
                  title="Controle de Acessos"
                  className={
                    isLinkActive(operationConstants.acessControl.path)
                      ? css.active
                      : ''
                  }
                  onClick={this.handleClick}
                >
                  <span className={css.navBar__list__icon}>
                    <MenuUsers className={css.iconNormal} />
                    <MenuUsersHover className={css.iconHover} />
                  </span>
                  <span className={css.navBar__list__text}>
                    Controle de Acessos
                  </span>
                </Link>
              </li>
            </IAM>

            {/* <IAM
              authority={user._id}
              companies={userCompaniesIds}
              resource="legislativeHouses:legislativeHouse"
              operation="READ"
            >
              <li>
                <Link
                  to="/casas-legislativas"
                  title="Casas Legislativas"
                  className={
                    isLinkActive('/casas-legislativas') ? css.active : ''
                  }
                  onClick={this.handleClick}
                >
                  <span className={css.navBar__list__icon}>
                    <MenuLegislativeAssembly className={css.iconNormal} />
                    <MenuLegislativeAssemblyHover className={css.iconHover} />
                  </span>
                  <span className={css.navBar__list__text}>
                    Casas Legislativas
                  </span>
                </Link>
              </li>
            </IAM> */}
            <IAM
              authority={user._id}
              companies={userCompaniesIds}
              resource="speeches:speech"
              operation="READ"
            >
              <li>
                <Link
                  to={operationConstants.speeches.path}
                  title="Discursos"
                  className={
                    isLinkActive(operationConstants.speeches.path) ? css.active : ''
                  }
                  onClick={this.handleClick}
                >
                  <span className={css.navBar__list__icon}>
                    <MenuSpeeches className={css.iconNormal} />
                    <MenuSpeechesHover className={css.iconHover} />
                  </span>
                  <span className={css.navBar__list__text}>Discursos</span>
                </Link>
              </li>
            </IAM>
            <IAM
              authority={user._id}
              companies={userCompaniesIds}
              resource="reports:dashboard"
              operation="READ"
            >
              <li>
                <Link
                  to={operationConstants.dash.path}
                  title="Dashboard"
                  className={
                    isLinkActive(operationConstants.dash.path) ? css.active : ''
                  }
                  onClick={this.handleClick}
                >
                  <span className={css.navBar__list__icon}>
                    <MenuDashboard className={css.iconNormal} />
                    <MenuDashboardHover className={css.iconHover} />
                  </span>
                  <span className={css.navBar__list__text}>Dashboard E&M</span>
                </Link>
              </li>
            </IAM>
            {/* <IAM
              authority={user._id}
              companies={userCompaniesIds}
              resource="reports:clientReports"
              operation="READ"
            >
              <li>
                <Link
                  to={operationConstants.specialReports.path}
                  title="Relatórios Especiais"
                  className={isLinkActive(operationConstants.specialReports.path) ? css.active : ''}
                  onClick={this.handleClick}
                >
                  <span className={css.navBar__list__icon}>
                    <MenuSpecialReports className={css.iconNormal} />
                    <MenuSpecialReportsHover className={css.iconHover} />
                  </span>
                  <span className={css.navBar__list__text}>RG Análise</span>
                </Link>
              </li>
            </IAM> */}
            <IAM
              authority={user._id}
              companies={userCompaniesIds}
              resource="documents:document"
              operation="CREATE"
            >
              <li>
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLScHQafFbWo_Sn_9KYhi93FMRhMGwKhfBYrQ-8kzTfgYh9HaOA/viewform"
                  target="_blank"
                  title="Suporte"
                  rel="noopener noreferrer"
                >
                  <span className={css.navBar__list__icon}>
                    <MenuLinks className={css.iconNormal} />
                    <MenuLinksHover className={css.iconHover} />
                  </span>
                  <span className={css.navBar__list__text}>Suporte da TI</span>
                </a>
              </li>
            </IAM>
            <IAM
              authority={user._id}
              companies={userCompaniesIds}
              resource="documents:document"
              operation="CREATE"
            >
              <li>
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSfYqX8fntDvxFQa9tplIfhr5LX8LVi7pdCNPz_nayevIxX9yw/viewform"
                  target="_blank"
                  title="Formulário de Divergências"
                  rel="noopener noreferrer"
                >
                  <span className={css.navBar__list__icon}>
                    <MenuLinks className={css.iconNormal} />
                    <MenuLinksHover className={css.iconHover} />
                  </span>
                  <span className={css.navBar__list__text}>Formulário de Divergências</span>
                </a>
              </li>
            </IAM>    
            <IAM
              authority={user._id}
              companies={userCompaniesIds}
              resource="documents:document"
              operation="CREATE"
            >
              <li>
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSfYqX8fntDvxFQa9tplIfhr5LX8LVi7pdCNPz_nayevIxX9yw/viewform"
                  target="_blank"
                  title="Formulário de Correspondentes"
                  rel="noopener noreferrer"
                >
                  <span className={css.navBar__list__icon}>
                    <MenuLinks className={css.iconNormal} />
                    <MenuLinksHover className={css.iconHover} />
                  </span>
                  <span className={css.navBar__list__text}>Formulário de Correspondentes</span>
                </a>
              </li>
            </IAM>                     
          </ul>
        </nav>
        <button
          type="button"
          onClick={() => onToggleMenu()}
          className={css.navBar__toggler}
        >
          <ExpandIcon />
        </button>
      </aside>
    );
  }
}

NavBar.propTypes = {
  expanded: PropTypes.bool,
  visible: PropTypes.bool,
  onToggleMenu: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  userCompaniesIds: PropTypes.array.isRequired,
};
NavBar.defaultProps = {
  expanded: false,
  visible: false,
};

export default withRouter(AuthInfo(NavBar));
