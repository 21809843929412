/* eslint-disable max-len */
import React from 'react';

const MenuNewDocumentHover = props => (
  <svg
    width={30}
    height={32}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <linearGradient x1="0%" y1="0%" x2="100.565%" y2="99.579%" id="mnd_c">
        <stop stopColor="#0F4BAF" offset="0%" />
        <stop stopColor="#009FFF" offset="100%" />
      </linearGradient>
      <path
        d="M14 0H1a1 1 0 0 0-1 1v22a1 1 0 0 0 1 1h20a1 1 0 0 0 1-1V8h-7a1 1 0 0 1-1-1V0zM4 17h14v2H4v-2zm0-5h14v2H4v-2zm6-3H4V7h6v2z"
        id="mnd_b"
      />
      <filter
        x="-31.8%"
        y="-20.8%"
        width="163.6%"
        height="158.3%"
        filterUnits="objectBoundingBox"
        id="mnd_a"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation={2}
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0.443137255 0 0 0 0 1 0 0 0 0.2 0"
          in="shadowBlurOuter1"
        />
      </filter>
      <path id="mnd_e" d="M21.414 6H16V.586z" />
      <filter
        x="-129.3%"
        y="-92.4%"
        width="358.6%"
        height="358.6%"
        filterUnits="objectBoundingBox"
        id="mnd_d"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation={2}
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0.443137255 0 0 0 0 1 0 0 0 0.2 0"
          in="shadowBlurOuter1"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path d="M-1-2h32v32H-1z" />
      <g fillRule="nonzero">
        <g transform="translate(4 0)">
          <use fill="#000" filter="url(#mnd_a)" xlinkHref="#mnd_b" />
          <use fill="url(#mnd_c)" xlinkHref="#mnd_b" />
        </g>
        <g transform="translate(4 0)">
          <use fill="#mnd_000" filter="url(#mnd_d)" xlinkHref="#mnd_e" />
          <use fill="url(#mnd_c)" xlinkHref="#mnd_e" />
        </g>
      </g>
    </g>
  </svg>
);

export default MenuNewDocumentHover;
