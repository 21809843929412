export const constants = {
  BOOT_READY: 'APP_BOOT_READY',
  CONFIG_LOADED: 'APP_CONFIG_LOADED',
  IAM_LOADED: 'APP_IAM_LOADED',
  IAM_RELOAD: 'APP_IAM_RELOAD',
};

/**
 * Set app boot status
 * @param status
 * @returns {{type: string, payload: *}}
 */
export const appBootReady = status => ({
  type: constants.BOOT_READY,
  payload: status,
});

/**
 * User config loaded
 * @returns {{type: string, payload: *}}
 */
export const configLoaded = config => ({
  type: constants.CONFIG_LOADED,
  payload: config,
});

/**
 * User iam loaded
 * @returns {{type: string, payload: *}}
 */
export const iamLoaded = (userId, iam) => ({
  type: constants.IAM_LOADED,
  payload: { userId, iam },
});

/**
 * User iam loaded
 * @returns {{type: string, payload: *}}
 */
export const reloadIAM = () => ({
  type: constants.IAM_RELOAD,
});
