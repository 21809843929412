import PropTypes from 'prop-types';
import React, { Component, Fragment, Suspense } from 'react';
import { Permissions } from 'react-rg-iam';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';

// Decorators
import AuthInfo from '../../lib/HOC/AuthInfo';

// Layout components
import AnalyticsTracker from '../../components/Analytics/AnalyticsTracker';
import Authentication from '../../components/Auth/Authentication';
import Header from '../../components/UI/Header/Header';
import Main from '../../components/UI/Main/Main';
import NavBar from '../../components/UI/NavBar/NavBar';

// Expansive Loading
import ExpansiveLoading from '../../components/UI/ExpansiveLoading/ExpansiveLoading';
import { operationConstants } from '../../utils/operationConstants';

import RouteCheckAcess from '../../components/Auth/RouteCheckAcess';

// Screens
const ScreensLogin = React.lazy(() => import('../Auth/Login'));
const ScreensDocuments = React.lazy(() => import('../Documents/Documents'));
const ScreensLinks = React.lazy(() => import('../Links/Links'));
const ScreensAccessControl = React.lazy(() =>
  import('../AccessControl/AccessControl'),
);
const ScreensRegisterPassword = React.lazy(() =>
  import('../Auth/RegisterPassword'),
);
const ScreensAlerts = React.lazy(() => import('../Alerts/Alerts'));
// const ScreensGlobalSearch = React.lazy(() =>
//   import('../GlobalSearch/GlobalSearch'),
// );
const ScreensLegislativeAssembly = React.lazy(() =>
  import('../LegislativeAssembly/LegislativeAssembly'),
);
const ScreensAuthors = React.lazy(() => import('../Authors/Authors'));
const ScreensDashboard = React.lazy(() => import('../Dashboard/Dashboard'));
const ScreensRadares = React.lazy(() => import('../Radares/Radares'));
const ScreensCompanies = React.lazy(() => import('../Companies/Companies'));
const ScreensTasksPanel = React.lazy(() => import('../TasksPanel/TasksPanel'));
const ScreensSpecialReports = React.lazy(() =>
  import('../SpecialReports/SpecialReport'),
);
const ScreensSpeeches = React.lazy(() => import('../Speeches/Speeches'));
const ScreensPrivateAgents = React.lazy(() =>
  import('../PrivateAgents/PrivateAgents'),
);
const ScreensOrganizations = React.lazy(() =>
  import('../Organizations/Organizations'),
);
const ScreensDocumentIndexing = React.lazy(() =>
  import('../DocumentIndexing/DocumentIndexing'),
);
const ScreensReports = React.lazy(() => import('../Reports/Reports'));
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
    };
    this.onToggleMenu = this.onToggleMenu.bind(this);
    this.handleContentClick = this.handleContentClick.bind(this);
  }

  onToggleMenu(forceState) {
    const { showMenu } = this.state;
    if (forceState !== showMenu) {
      this.setState({
        showMenu: typeof forceState !== 'undefined' ? forceState : !showMenu,
      });
    }
  }

  handleContentClick() {
    this.onToggleMenu(false);
  }

  render() {
    const { userIsAuthenticated, user, userCompaniesIds } = this.props;
    const { showMenu } = this.state;

    return userIsAuthenticated ? (
      <Authentication>
        {() => (
          <Fragment>
            <AnalyticsTracker />
            <Header onToggleMenu={this.onToggleMenu} />
            <NavBar visible={showMenu} onToggleMenu={this.onToggleMenu} />
            <Main onClick={this.handleContentClick}>
              <Suspense fallback={<ExpansiveLoading />}>
                <Switch>
                  <Route path={operationConstants.reports.path}>
                    <ScreensReports />
                  </Route>
                  <RouteCheckAcess 
                    path={operationConstants.privateAgent.path}
                    operation={operationConstants.privateAgent.auth.operation}
                    resource={operationConstants.privateAgent.auth.resource}
                    user={user} userCompaniesIds={userCompaniesIds}
                  >
                    <ScreensPrivateAgents />
                  </RouteCheckAcess>
                  {/* {Permissions.checkAccess(
                    user._id,
                    'organizations:organization',
                    'READ',
                    userCompaniesIds,
                  ) ? (
                    <Route path={operationConstants.organizations.path}>
                      <ScreensOrganizations />
                    </Route>
                  ) : null} */}
                  <RouteCheckAcess 
                    path={operationConstants.taskPanel.path}
                    operation={operationConstants.taskPanel.auth.operation}
                    resource={operationConstants.taskPanel.auth.resource}
                    user={user} userCompaniesIds={userCompaniesIds}
                  >
                    <ScreensTasksPanel />
                  </RouteCheckAcess>
                  <RouteCheckAcess 
                    path={operationConstants.specialReports.path}
                    operation={operationConstants.specialReports.auth.operation}
                    resource={operationConstants.specialReports.auth.resource}
                    user={user} userCompaniesIds={userCompaniesIds}  
                  >
                    <ScreensSpecialReports />
                  </RouteCheckAcess>
                  <RouteCheckAcess 
                    path={operationConstants.documents.path}
                    operation={operationConstants.documents.auth.operation}
                    resource={operationConstants.documents.auth.resource}
                    user={user} userCompaniesIds={userCompaniesIds}  
                  >
                    <ScreensDocuments />
                  </RouteCheckAcess>
                  <RouteCheckAcess 
                    path={operationConstants.documents.oldPath}
                    operation={operationConstants.documents.auth.operation}
                    resource={operationConstants.documents.auth.resource}
                    user={user} userCompaniesIds={userCompaniesIds}  
                  >
                    <ScreensDocuments />
                  </RouteCheckAcess>
                  <RouteCheckAcess 
                      path={operationConstants.acessControl.path}
                      operation={operationConstants.acessControl.auth.operation}
                      resource={operationConstants.acessControl.auth.resource}
                      user={user} userCompaniesIds={userCompaniesIds}  
                    >
                    <ScreensAccessControl />
                  </RouteCheckAcess>
                  <RouteCheckAcess 
                      path={operationConstants.companies.path}
                      operation={operationConstants.companies.auth.operation}
                      resource={operationConstants.companies.auth.resource}
                      user={user} userCompaniesIds={userCompaniesIds} 
                    >
                    <ScreensCompanies />
                  </RouteCheckAcess>
                  <RouteCheckAcess 
                    path={operationConstants.links.path}
                    operation={operationConstants.links.auth.operation}
                    resource={operationConstants.links.auth.resource}
                    user={user} userCompaniesIds={userCompaniesIds} 
                  >
                    <ScreensLinks />
                  </RouteCheckAcess>
                  <RouteCheckAcess 
                    path={operationConstants.speeches.path}
                    operation={operationConstants.speeches.auth.operation}
                    resource={operationConstants.speeches.auth.resource}
                    user={user} userCompaniesIds={userCompaniesIds} 
                  >
                    <ScreensSpeeches />
                  </RouteCheckAcess>
                  <RouteCheckAcess 
                    path={operationConstants.dash.path}
                    operation={operationConstants.dash.auth.operation}
                    resource={operationConstants.dash.auth.resource}
                    user={user} userCompaniesIds={userCompaniesIds}
                  >
                    <ScreensDashboard />
                  </RouteCheckAcess>
                  <RouteCheckAcess 
                    path={operationConstants.alerts.path}
                    operation={operationConstants.alerts.auth.operation}
                    resource={operationConstants.alerts.auth.resource}
                    user={user} userCompaniesIds={userCompaniesIds}  
                  >
                    <ScreensAlerts />
                  </RouteCheckAcess>
                  <RouteCheckAcess 
                    path={operationConstants.radares.path}
                    operation={operationConstants.radares.auth.operation}
                    resource={operationConstants.radares.auth.resource}
                    user={user} userCompaniesIds={userCompaniesIds}
                  >
                    <ScreensRadares />
                  </RouteCheckAcess>
                  {/* {Permissions.checkAccess(
                    user._id,
                    'legislativeHouses:legislativeHouse',
                    'READ',
                    userCompaniesIds,
                  ) ? (
                    <Route path="/casas-legislativas">
                      <ScreensLegislativeAssembly />
                    </Route>
                  ) : null} */}
                  <RouteCheckAcess 
                    path={operationConstants.authors.path}
                    operation={operationConstants.authors.auth.operation}
                    resource={operationConstants.authors.auth.resource}
                    user={user} userCompaniesIds={userCompaniesIds}
                  >
                    <ScreensAuthors />
                  </RouteCheckAcess>

                  <RouteCheckAcess
                      path={operationConstants.authors.oldPath}
                      operation={operationConstants.authors.auth.operation}
                      resource={operationConstants.authors.auth.resource}
                      user={user} userCompaniesIds={userCompaniesIds}
                      render={p => (
                        <Redirect
                          to={p.location.pathname.replace(
                            operationConstants.authors.oldPath.split('/')[1],
                            operationConstants.authors.path.split('/')[1],
                          )}
                        />
                      )}
                    />

                  <RouteCheckAcess 
                    path={operationConstants.documentIndexing.path}
                    operation={operationConstants.documentIndexing.auth.operation}
                    resource={operationConstants.documentIndexing.auth.resource}
                    user={user} userCompaniesIds={userCompaniesIds}
                    >
                    <ScreensDocumentIndexing />
                  </RouteCheckAcess>

                  <RouteCheckAcess
                    path="/"
                    operation={operationConstants.documents.search.auth.operation}
                    resource={operationConstants.documents.search.auth.resource}
                    user={user} userCompaniesIds={userCompaniesIds}
                    render={() => (
                      <Redirect to={operationConstants.documents.search.path} />
                    )}
                  />
                </Switch>
              </Suspense>
            </Main>
          </Fragment>
        )}
      </Authentication>
    ) : (
      <Fragment>
        <Suspense fallback={<ExpansiveLoading />}>
          <AnalyticsTracker />
          <Switch>
            <Route path={operationConstants.auth.registerPassword.path}>
              <ScreensRegisterPassword />
            </Route>
            <Route path={operationConstants.auth.login.path}>
              <ScreensLogin />
            </Route>
          </Switch>
        </Suspense>
      </Fragment>
    );
  }
}

Index.propTypes = {
  userIsAuthenticated: PropTypes.bool,
  user: PropTypes.object,
  userCompaniesIds: PropTypes.array,
};

Index.defaultProps = {
  userIsAuthenticated: false,
  user: null,
  userCompaniesIds: null,
};

export default withRouter(AuthInfo(Index));
