import React from 'react';

const MenuNewDocument = ({ primaryColor, secondaryColor, ...props }) => (
  <svg width={22} height={32} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M-5-4h32v32H-5z" />
      <path fill="#8FA4AE" className="fill" fillRule="nonzero" d="M15 1v6h6z" />
      <path stroke="#8FA4AE" className="stroke" strokeWidth={2} d="M15 1v6h6" />
      <path
        stroke="#8FA4AE"
        className="stroke"
        strokeWidth={2}
        strokeLinecap="square"
        d="M15 1H1v22h20V7z"
      />
      <path
        d="M6 7h4M6 12h10M6 17h10"
        stroke="#CFD8DC"
        className="stroke"
        strokeWidth={2}
        strokeLinecap="square"
      />
    </g>
  </svg>
);

export default MenuNewDocument;
