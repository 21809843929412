import React from 'react';
import PropTypes from 'prop-types';
import { IAM } from 'react-rg-iam';
import css from './Header.module.scss';

// UI
import CompanyLogo from './CompanyLogo/CompanyLogo';
// import GlobalSearch from './GlobalSearch/GlobalSearch';

import UserActions from './UserActions/UserActions';
import MobileMenuToggleIcon from '../Icons/MobileMenuToggle';
import AuthInfo from '../../../lib/HOC/AuthInfo';


const Header = props => {
  const { onToggleMenu, user, userCompaniesIds } = props;
  const { primaryCompany } = user;
  return (
    <div className={css.header}>
      <div className={css.header__logo}>
        <CompanyLogo company={primaryCompany} />
        <button type="button" onClick={() => onToggleMenu()}>
          <MobileMenuToggleIcon />
        </button>
      </div>
      <IAM
        authority={user._id}
        companies={userCompaniesIds}
        resource="documents:globalSearch"
        operation="READ"
      >
        {/* <IAM.Allowed>
          <GlobalSearch />
        </IAM.Allowed> */}
        <IAM.Denied>
          <div style={{ width: '100%' }} />
        </IAM.Denied>
      </IAM>
      <div className={css.header__actions}>
        <UserActions />
      </div>
    </div>
  );
};

Header.propTypes = {
  onToggleMenu: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  userCompaniesIds: PropTypes.array.isRequired,
};
Header.defaultProps = {};

export default AuthInfo(Header);
