/* eslint-disable max-len */
import React from 'react';

const MenuDashboardHover = props => (
  <svg width={26} height={26} {...props}>
    <defs>
      <linearGradient x1="0%" y1="50%" y2="50%" id="mndb_a">
        <stop stopColor="#0D55B8" offset="0%" />
        <stop stopColor="#0296F6" offset="100%" />
      </linearGradient>
      <linearGradient x1="0%" y1="50%" y2="50%" id="mndb_b">
        <stop stopColor="#0395F5" offset="0%" />
        <stop stopColor="#0D56B9" offset="100%" />
      </linearGradient>
      <linearGradient x1="50%" y1="100%" x2="50%" y2="0%" id="mndb_d">
        <stop stopColor="#0D58BB" offset="0%" />
        <stop stopColor="#0295F5" offset="100%" />
      </linearGradient>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="mndb_c">
        <stop stopColor="#1C8FDD" offset="0%" />
        <stop stopColor="#0C56B8" offset="100%" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path d="M-3-2h32v32H-3z" />
      <g fillRule="nonzero">
        <path
          d="M25.99 25H.01v-2h25.98v2z"
          fill="url(#mndb_a)"
          transform="translate(0 1)"
        />
        <path
          fill="url(#mndb_b)"
          d="M.036 12.051h2v5.941h-2z"
          transform="translate(2 6)"
        />
        <path
          fill="url(#mndb_a)"
          d="M4 10.285h2v7.707H4z"
          transform="translate(2 6)"
        />
        <path
          fill="url(#mndb_b)"
          d="M8 9.473h2v8.519H8z"
          transform="translate(2 6)"
        />
        <path
          fill="url(#mndb_a)"
          d="M11.978 6h2v11.992h-2z"
          transform="translate(2 6)"
        />
        <path
          fill="url(#mndb_b)"
          d="M16 2.578h2v15.414h-2z"
          transform="translate(2 6)"
        />
        <path
          fill="url(#mndb_a)"
          d="M20 0h2v17.977h-2z"
          transform="translate(2 6)"
        />
        <path
          d="M1.184 15.115l4.401-3.574a.497.497 0 0 1 .393-.105l4.082.665 3.728-5.7.496-.76a.498.498 0 0 1 .332-.217l4.238-.733L23.14.156l.723.685-4.401 4.655a.498.498 0 0 1-.277.148l-4.188.725-4.282 6.548a.498.498 0 0 1-.496.219l-4.181-.68-4.228 3.433-.627-.774z"
          stroke="url(#mndb_c)"
          strokeWidth={0.5}
          fill="url(#d)"
          transform="translate(0 1)"
        />
      </g>
    </g>
  </svg>
);

export default MenuDashboardHover;
