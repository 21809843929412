/* eslint-disable max-len */
import React from 'react';

const MenuUsers = props => (
  <svg
    width={32}
    height={32}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <linearGradient x1="0%" y1="0%" x2="100.565%" y2="99.579%" id="mnu_c">
        <stop stopColor="#0F4BAF" offset="0%" />
        <stop stopColor="#009FFF" offset="100%" />
      </linearGradient>
      <path
        d="M4 17.688V20h16v-2.312a3.983 3.983 0 0 0-2.463-3.695C15.95 13.334 14.087 13 12 13c-2.087 0-3.95.334-5.536.992A3.984 3.984 0 0 0 4 17.688z"
        id="mnu_b"
      />
      <filter
        x="-43.8%"
        y="-71.4%"
        width="187.5%"
        height="300%"
        filterUnits="objectBoundingBox"
        id="mnu_a"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation={2}
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0.443137255 0 0 0 0 1 0 0 0 0.2 0"
          in="shadowBlurOuter1"
        />
      </filter>
      <circle id="mnu_e" cx={12} cy={7} r={5} />
      <filter
        x="-70%"
        y="-50%"
        width="240%"
        height="240%"
        filterUnits="objectBoundingBox"
        id="mnu_d"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation={2}
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0.443137255 0 0 0 0 1 0 0 0 0.2 0"
          in="shadowBlurOuter1"
        />
      </filter>
      <path d="M2 2h4V0H1a1 1 0 0 0-1 1v5h2V2z" id="mnu_g" />
      <filter
        x="-116.7%"
        y="-83.3%"
        width="333.3%"
        height="333.3%"
        filterUnits="objectBoundingBox"
        id="mnu_f"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation={2}
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0.443137255 0 0 0 0 1 0 0 0 0.2 0"
          in="shadowBlurOuter1"
        />
      </filter>
      <path d="M23 0h-5v2h4v4h2V1a1 1 0 0 0-1-1z" id="mnu_i" />
      <filter
        x="-116.7%"
        y="-83.3%"
        width="333.3%"
        height="333.3%"
        filterUnits="objectBoundingBox"
        id="mnu_h"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation={2}
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0.443137255 0 0 0 0 1 0 0 0 0.2 0"
          in="shadowBlurOuter1"
        />
      </filter>
      <path d="M2 18H0v5a1 1 0 0 0 1 1h5v-2H2v-4z" id="mnu_k" />
      <filter
        x="-116.7%"
        y="-83.3%"
        width="333.3%"
        height="333.3%"
        filterUnits="objectBoundingBox"
        id="mnu_j"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation={2}
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0.443137255 0 0 0 0 1 0 0 0 0.2 0"
          in="shadowBlurOuter1"
        />
      </filter>
      <path d="M22 22h-4v2h5a1 1 0 0 0 1-1v-5h-2v4z" id="mnu_m" />
      <filter
        x="-116.7%"
        y="-83.3%"
        width="333.3%"
        height="333.3%"
        filterUnits="objectBoundingBox"
        id="mnu_l"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation={2}
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0.443137255 0 0 0 0 1 0 0 0 0.2 0"
          in="shadowBlurOuter1"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path d="M0-3h32v32H0z" />
      <g fillRule="nonzero">
        <g transform="translate(4 0)">
          <use fill="#000" filter="url(#mnu_a)" xlinkHref="#mnu_b" />
          <use fill="url(#mnu_c)" xlinkHref="#mnu_b" />
        </g>
        <g transform="translate(4 0)">
          <use fill="#000" filter="url(#mnu_d)" xlinkHref="#mnu_e" />
          <use fill="url(#mnu_c)" xlinkHref="#mnu_e" />
        </g>
        <g transform="translate(4 0)">
          <use fill="#000" filter="url(#mnu_f)" xlinkHref="#mnu_g" />
          <use fill="url(#mnu_c)" xlinkHref="#mnu_g" />
        </g>
        <g transform="translate(4 0)">
          <use fill="#000" filter="url(#mnu_h)" xlinkHref="#mnu_i" />
          <use fill="url(#mnu_c)" xlinkHref="#mnu_i" />
        </g>
        <g transform="translate(4 0)">
          <use fill="#000" filter="url(#mnu_j)" xlinkHref="#mnu_k" />
          <use fill="url(#mnu_c)" xlinkHref="#mnu_k" />
        </g>
        <g transform="translate(4 0)">
          <use fill="#000" filter="url(#mnu_l)" xlinkHref="#mnu_m" />
          <use fill="url(#mnu_c)" xlinkHref="#mnu_m" />
        </g>
      </g>
    </g>
  </svg>
);

export default MenuUsers;
