import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

const client = new ApolloClient({
  ssrMode: false,
  link: createUploadLink({
    uri: process.env.REACT_APP_API_URL,
  }),
  cache: new InMemoryCache({
    addTypename: false,
  }),
  defaultOptions,
});

export default client;
