/* eslint-disable max-len */
import React from 'react';

const MenuAuthors = props => (
  <svg width={28} height={27} {...props}>
    <g strokeWidth={2} fill="none" fillRule="evenodd">
      <path
        d="M6.437 11.389l-4.66 2.552c-.482.263-.777.736-.777 1.247V18h16v-2.812c0-.511-.295-.985-.777-1.247l-4.66-2.552"
        stroke="#8FA4AE"
        className="stroke"
      />
      <path
        d="M4.765 5.583a9.323 9.323 0 0 0 1.555.14c1.538 0 2.925-.363 3.923-.945.997.582 2.396.944 3.933.944"
        stroke="#CFD8DC"
        className="stroke"
      />
      <path
        d="M4.765 5.857C4.765 3.174 6.87 1 9.47 1c2.599 0 4.705 2.174 4.705 4.857 0 4.048-2.106 6.476-4.705 6.476-2.6 0-4.706-2.428-4.706-6.476z"
        stroke="#8FA4AE"
        className="stroke"
        strokeLinecap="square"
      />
      <path
        d="M13.136 17L7.02 20.474C6.387 20.832 6 21.477 6 22.172V26h21v-3.828c0-.696-.387-1.34-1.02-1.698L19.864 17"
        className="inverse-stroke"
        stroke="#8FA4AE"
        fill="#FFF"
      />
      <path
        d="M19 21h3"
        stroke="#CFD8DC"
        className="inverse-stroke"
        strokeLinecap="square"
      />
      <path
        d="M10 9.429C10 5.878 12.91 3 16.5 3S23 5.878 23 9.429C23 14.786 20.09 18 16.5 18S10 14.786 10 9.429z"
        className="inverse-stroke"
        stroke="#8FA4AE"
        fill="#FFF"
        strokeLinecap="square"
      />
      <path
        d="M11 8.706A6.203 6.203 0 0 0 12.817 9c1.798 0 3.42-.767 4.585-2A6.32 6.32 0 0 0 22 9"
        stroke="#CFD8DC"
        className="stroke"
      />
    </g>
  </svg>
);

export default MenuAuthors;
