import { Component } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';

class Analytics extends Component {
  componentDidUpdate(prevProps) {
    const { location } = this.props;
    const prevLocation = prevProps.location; //eslint-disable-line
    if (
      location.pathname !== prevLocation.pathname ||
      location.search !== prevLocation.search
    ) {
      this.sendPageChange(location.pathname, location.search);
    }
  }

  // eslint-disable-next-line
  sendPageChange(pathname, search = '') {
    const page = pathname + search;
    ReactGA.set({ page });
    ReactGA.pageview(page);
  }

  render() {
    return null;
  }
}

Analytics.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Analytics;
