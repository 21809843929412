/* eslint-disable max-len */
import React from 'react';

const MenuAlertsHover = props => (
  <svg
    width={33}
    height={28}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <linearGradient x1="0%" y1="0%" x2="100.565%" y2="99.579%" id="mnal_c">
        <stop stopColor="#0F4BAF" offset="0%" />
        <stop stopColor="#009FFF" offset="100%" />
      </linearGradient>
      <path
        d="M17.57 1.178a.998.998 0 0 0-.921-.115l-16 6C.259 7.21 0 7.583 0 8v6c0 .417.259.79.649.937L2 15.443V19a1 1 0 0 0 .835.986l6 1A1 1 0 0 0 10 20v-1.557l6.649 2.493a.994.994 0 0 0 .921-.114c.269-.187.43-.494.43-.822V2c0-.328-.161-.635-.43-.822zM8 18.819l-4-.667v-1.96l4 1.5v1.127z"
        id="mnal_b"
      />
      <filter
        x="-38.9%"
        y="-25%"
        width="177.8%"
        height="170%"
        filterUnits="objectBoundingBox"
        id="mnal_a"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation={2}
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0.443137255 0 0 0 0 1 0 0 0 0.2 0"
          in="shadowBlurOuter1"
        />
      </filter>
      <path id="mnal_e" d="M20 10h4v2h-4z" />
      <filter
        x="-175%"
        y="-250%"
        width="450%"
        height="800%"
        filterUnits="objectBoundingBox"
        id="mnal_d"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation={2}
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0.443137255 0 0 0 0 1 0 0 0 0.2 0"
          in="shadowBlurOuter1"
        />
      </filter>
      <path id="mnal_g" d="M18.66 5h4.001v2H18.66z" />
      <filter
        x="-175%"
        y="-250%"
        width="449.9%"
        height="800%"
        filterUnits="objectBoundingBox"
        id="mnal_f"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation={2}
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0.443137255 0 0 0 0 1 0 0 0 0.2 0"
          in="shadowBlurOuter1"
        />
      </filter>
      <path id="mnal_i" d="M19.661 13.999h2V18h-2z" />
      <filter
        x="-350%"
        y="-125%"
        width="800%"
        height="449.9%"
        filterUnits="objectBoundingBox"
        id="mnal_h"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation={2}
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0.443137255 0 0 0 0 1 0 0 0 0.2 0"
          in="shadowBlurOuter1"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path d="M0-5h32v32H0z" />
      <g fillRule="nonzero">
        <g transform="translate(4 0)">
          <use fill="#000" filter="url(#mnal_a)" xlinkHref="#mnal_b" />
          <use fill="url(#mnal_c)" xlinkHref="#mnal_b" />
        </g>
        <g transform="translate(4 0)">
          <use fill="#000" filter="url(#mnal_d)" xlinkHref="#mnal_e" />
          <use fill="url(#mnal_c)" xlinkHref="#mnal_e" />
        </g>
        <g transform="rotate(-29.993 24.527 -.966)">
          <use fill="#000" filter="url(#mnal_f)" xlinkHref="#mnal_g" />
          <use fill="url(#mnal_c)" xlinkHref="#mnal_g" />
        </g>
        <g transform="rotate(-60.007 23.526 13.036)">
          <use fill="#000" filter="url(#mnal_h)" xlinkHref="#mnal_i" />
          <use fill="url(#mnal_c)" xlinkHref="#mnal_i" />
        </g>
      </g>
    </g>
  </svg>
);

export default MenuAlertsHover;
