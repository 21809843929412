import React from 'react';

const MenuTaskHover = props => (
  <svg width={24} height={29} {...props}>
    <defs>
      <linearGradient
        x1="18.877%"
        y1="0%"
        x2="75.734%"
        y2="100%"
        id="taskIcon_svg__a"
      >
        <stop stopColor="#0E50B4" offset="0%" />
        <stop stopColor="#009BFB" offset="100%" />
      </linearGradient>
    </defs>
    <g strokeWidth={2} fill="none" fillRule="evenodd" strokeLinecap="square">
      <path
        stroke="url(#taskIcon_svg__a)"
        d="M18.944 4H22v23H0V4h3.056"
        transform="translate(1 1)"
      />
      <path stroke="#009FFF" d="M8 17.667L11.214 21 17 15" />
      <path
        d="M14.556 3.182C14.556 1.425 13.187 0 11.5 0S8.444 1.425 8.444 3.182H6V7h11V3.182h-2.444z"
        stroke="url(#taskIcon_svg__a)"
        transform="translate(1 1)"
      />
    </g>
  </svg>
);

export default MenuTaskHover;
