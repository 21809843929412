import React from 'react';

const MenuAlerts = props => (
  <svg width={24} height={28} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M-4-5h32v32H-4z" />
      <g strokeWidth={2}>
        <path stroke="#8FA4AE" d="M9 17v3l-6-1v-4.2M5 6.5v9" />
        <path stroke="#8FA4AE" strokeLinecap="square" d="M17 20L1 14V8l16-6z" />
        <path
          d="M21 11h2M19.8 6.5l1.7-1M19.8 15.5l1.7 1"
          stroke="#CFD8DC"
          strokeLinecap="square"
        />
      </g>
    </g>
  </svg>
);

export default MenuAlerts;
