import React from 'react';
import PropTypes from 'prop-types';

import css from './Main.module.scss';

const Main = props => {
  const { children, fullWidth, ...otherProps } = props;
  return (
    <main
      className={`${css.main} ${fullWidth ? css['main--full-width'] : ''}`}
      {...otherProps}
    >
      {children}
    </main>
  );
};

Main.propTypes = {
  children: PropTypes.node,
  fullWidth: PropTypes.bool,
};
Main.defaultProps = {
  children: null,
  fullWidth: false,
};

export default Main;
