import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import logdown from 'logdown';

// UI
import { Loading, Spacer } from '@radargovernamental/orbit-style';

// Apollo client to call mutation
import gql from 'graphql-tag';
import apolloClient from '../../config/apollo-client';

// Decorators
import AuthInfo from '../../lib/HOC/AuthInfo';

// Actions
import { login, logout } from '../../store/auth/actions';
import {
  configLoaded,
  iamLoaded,
  reloadIAM,
  appBootReady,
} from '../../store/app/actions';

const logger = logdown('BOOT');

const ME = gql`
  query me($token: String) {
    viewer(token: $token) {
      me(checkLogin: true) {
        _id
        firstName
        lastName
        fullName
        email
        aud
        premium
        hasFederalAccess
        primaryCompany {
          _id
          name
          images {
            logo {
              url
            }
          }
        }
        restrictAccess {
          cities
          legislativeHouses
        }
        companiesWithAccess {
          _id
          name
          createdAt
          images {
            logo {
              url
            }
          }
          categories
        }
      }
    }
  }
`;

const IAM = gql`
  query me($token: String) {
    viewer(token: $token) {
      me(checkLogin: true) {
        _id
        iam {
          list {
            companyId
            resource
            all
            operation
            attributes
          }
        }
      }
    }
  }
`;

const CONFIG = gql`
  query loadConfig($token: String) {
    viewer(token: $token) {
      config {
        legislativeAssembly {
          title
          state
          city
        }
        documentIndexing {
          title
        }
        engagementResults {
          title
        }
        documentEngagementStrategy {
          title
        }
        documentLegalImpact {
          title
        }
        documentCategory {
          title
        }
        documentMainField {
          title
        }
        documentTheme {
          title
        }
        documentStatus {
          _id
          value
          title
          nextSteps
          progress
          sphere
        }
        documentType {
          title
          value
          family
          federal
        }
        documentSphere {
          title
          value
        }
        documentFamily {
          title
          value
        }
        politicalParties {
          party
          title
        }
        projectTypes {
          projectType
          title
        }
        rito {
          title
        }
        states {
          uf
          title
          region
        }
        links {
          maxDocumentsNumberGeneration
        }
        panelTaskTypes {
          label
          value
        }
        panelTaskStatus {
          label
          value
        }
        panelVisitedDocumentTypes {
          label
          value
        }
        panelPendingTaskType {
          _id
          title
          value
          options {
            value
          }
        }
        scrappedHouses {
          legislativeHouse
          templateUrl
        }
      }
    }
  }
`;

class Authentication extends Component {
  componentDidMount() {
    const { dispatch } = this.props;
    this.checkUser()
      .then(() => this.loadConfig())
      .then(() => this.loadIAM())
      .then(() => {
        logger.info('-- CHAIN READY');
        dispatch(appBootReady(true));
      });
  }

  checkUser() {
    logger.info('[USER] CHECKING');
    return new Promise(resolve => {
      const { dispatch, userIsAuthenticated, authToken } = this.props;
      if (userIsAuthenticated && authToken) {
        apolloClient
          .query({
            variables: { token: authToken },
            query: ME,
            fetchPolicy: 'network-only',
          })
          .then(result => {
            if (result.errors) return Promise.reject(result.errors);
            dispatch(login(result.data.viewer.me, authToken));
            return resolve();
          })
          .catch(() => dispatch(logout()));
      } else {
        logger.info('[USER] MUST LOGIN');
      }
    });
  }

  loadConfig() {
    logger.info('[CONFIG] CHECKING');
    return new Promise(resolve => {
      const { dispatch, authToken, lastConfigSyncAt } = this.props;
      logger.info('[CONFIG] LAST SYNC', lastConfigSyncAt);
      if (
        lastConfigSyncAt &&
        moment(lastConfigSyncAt)
          .add(3, 'days')
          .isAfter(moment())
      ) {
        return resolve();
      }
      logger.info('[CONFIG] SYNCING config');
      return apolloClient
        .query({
          variables: { token: authToken },
          query: CONFIG,
          fetchPolicy: 'network-only',
        })
        .then(result => {
          if (result.errors) return Promise.reject(result.errors);
          dispatch(configLoaded(result.data.viewer.config));
          return resolve();
        })
        .catch(() => dispatch(logout()));
    });
  }

  loadIAM() {
    logger.info('[IAM] CHECKING');
    return new Promise(resolve => {
      const { dispatch, authToken, lastIAMSyncAt } = this.props;
      logger.info('[IAM] LAST SYNC', lastIAMSyncAt);
      if (
        lastIAMSyncAt &&
        moment(lastIAMSyncAt)
          .add(12, 'hours')
          .isAfter(moment())
      ) {
        dispatch(reloadIAM());
        return resolve();
      }

      logger.info('[IAM] SYNCING IAM');
      return apolloClient
        .query({
          variables: { token: authToken },
          query: IAM,
          fetchPolicy: 'network-only',
        })
        .then(result => {
          if (result.errors) return Promise.reject(result.errors);
          dispatch(
            iamLoaded(result.data.viewer.me._id, result.data.viewer.me.iam),
          );
          return resolve();
        })
        .catch(() => dispatch(logout()));
    });
  }

  render() {
    const { children, bootReady } = this.props;
    return (
      <div style={{ width: '100%' }}>
        {bootReady ? (
          children()
        ) : (
          <Spacer
            mtLg={3}
            mtMd={3}
            mtSm={3}
            mtXs={3}
            style={{ textAlign: 'center' }}
          >
            <Loading white inline lg />
          </Spacer>
        )}
      </div>
    );
  }
}

Authentication.propTypes = {
  authToken: PropTypes.string,
  children: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  lastConfigSyncAt: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  lastIAMSyncAt: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  userIsAuthenticated: PropTypes.bool,
  bootReady: PropTypes.bool,
};
Authentication.defaultProps = {
  authToken: null,
  lastConfigSyncAt: null,
  lastIAMSyncAt: null,
  userIsAuthenticated: false,
  bootReady: false,
};

export default AuthInfo(
  connect(state => ({
    lastConfigSyncAt: state.app.lastSyncAt,
    lastIAMSyncAt: state.app.lastIAMSyncAt,
    bootReady: state.app.bootReady,
  }))(Authentication),
);
