import { Permissions } from 'react-rg-iam';

import { constants } from './actions';
import { constants as authConstants } from '../auth/actions';

const DEFAULT_STATE = {
  bootReady: false,
  lastSyncAt: null,
  lastIAMSyncAt: null,
  data: {},
};

/**
 * Reducer map
 * @param state
 * @param action
 * @returns {*}
 */
const app = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case 'persist/REHYDRATE':
      // only bootReady must not persist
      return Object.assign({}, state, {
        ...((action.payload || {}).app || {}),
        bootReady: false,
      });

    case constants.BOOT_READY:
      return Object.assign({}, state, { bootReady: action.payload });

    case constants.CONFIG_LOADED:
      return Object.assign({}, state, {
        lastSyncAt: new Date(),
        data: action.payload,
      });

    case constants.IAM_LOADED:
      Permissions.setAccess(action.payload.userId, action.payload.iam.list);
      return Object.assign({}, state, {
        lastIAMSyncAt: new Date(),
        iam: Object.assign(
          {},
          {
            ACCESS: Permissions.getAccess(),
            userId: action.payload.userId,
          },
        ),
      });

    case constants.IAM_RELOAD:
      Permissions.setAccess(state.iam.userId, null, state.iam.ACCESS);
      return state;

    // User logout
    case authConstants.LOGOUT:
      return DEFAULT_STATE;

    default:
      return state;
  }
};

export default app;
