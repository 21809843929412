import React from 'react';

const MenuLinks = props => (
  <svg width={22} height={32} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M-5-4h32v32H-5z" />
      <g strokeLinecap="square" strokeWidth={2}>
        <path stroke="#8FA4AE" d="M1 1h20v22H1z" />
        <path
          d="M8 10V8a3 3 0 1 1 6 0v2M14 14v2a3 3 0 1 1-6 0v-2M11 14v-4"
          stroke="#CFD8DC"
        />
      </g>
    </g>
  </svg>
);

export default MenuLinks;
