import React from 'react';

const MenuSpeeches = props => (
  <svg width={30} height={29} {...props}>
    <defs>
      <linearGradient x1="18.877%" y1="0%" x2="75.734%" y2="100%" id="ms_a">
        <stop stopColor="#0E50B4" offset="0%" />
        <stop stopColor="#009BFB" offset="100%" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        d="M14.545 2a5.999 5.999 0 0 0-4.19 10.166h-.05c.056.06.115.117.174.175l.413.394-.481.308C8.673 14.153 7.46 15.523 7 17.32h1.953c.64-1.87 2.352-3.085 4.536-3.507.329-.064.6-.298.711-.615l.11-.312-.11-.312a.945.945 0 0 0-.646-.6 4.12 4.12 0 0 1-3.046-3.97 4.12 4.12 0 0 1 4.077-4.114 4.12 4.12 0 0 1 4.076 4.115 4.12 4.12 0 0 1-3.046 3.969.946.946 0 0 0-.646.6l-.11.312.11.312a.945.945 0 0 0 .712.615c2.183.422 3.895 1.636 4.535 3.507h1.953c-.461-1.797-1.674-3.166-3.411-4.276l-.481-.308.413-.394c.06-.058.117-.116.175-.175h-.051A5.999 5.999 0 0 0 14.624 2h-.079z"
        fill="url(#ms_a)"
      />
      <path
        d="M22.848 2.114L19.63 10.58H9.002L5.784 2.114h17.064zm2.567-.442h1.953c.726 0 1.265-.38 1.265-.808 0-.43-.54-.81-1.265-.81H1.265C.539.055 0 .435 0 .865c0 .428.54.808 1.265.808h1.952l3.473 9.94c.104.371.613.665 1.245.665h12.762c.634 0 1.141-.294 1.246-.667l3.472-9.938z"
        transform="translate(0 16.368)"
        fill="url(#ms_a)"
      />
      <path
        d="M21.398 1c-.156 0-.282.11-.282.246v3.246c0 .135.126.245.282.245h.867c.316 0 .573.224.573.5v.693l1.253-1.053a.62.62 0 0 1 .396-.14h3.548c.155 0 .281-.11.281-.245V1.246c0-.135-.126-.246-.281-.246h-6.637zm.728 6.465a.496.496 0 0 1-.338-.454V5.662h-.286c-.764 0-1.386-.551-1.386-1.23V1.23c0-.679.622-1.23 1.386-1.23h6.446c.764 0 1.386.551 1.386 1.23v3.202c0 .679-.622 1.23-1.386 1.23h-3.221L22.73 7.367a.614.614 0 0 1-.604.098z"
        fill="#009FFF"
      />
      <g fill="#009FFF">
        <path d="M24.875 3.335a.606.606 0 0 1-.426-.18.596.596 0 0 1-.174-.428.59.59 0 0 1 .174-.42.607.607 0 0 1 .846 0 .587.587 0 0 1 0 .847.6.6 0 0 1-.42.18M26.875 3.335a.606.606 0 0 1-.426-.18.596.596 0 0 1-.174-.428.59.59 0 0 1 .174-.42.607.607 0 0 1 .846 0 .587.587 0 0 1 0 .847.6.6 0 0 1-.42.18M22.6 3.335a.606.606 0 0 1-.426-.18.596.596 0 0 1-.174-.428.59.59 0 0 1 .174-.42.607.607 0 0 1 .846 0 .585.585 0 0 1 0 .847.6.6 0 0 1-.42.18" />
      </g>
    </g>
  </svg>
);

export default MenuSpeeches;
