import React from 'react';

const MenuPrivateAgents = props => (
  <svg width={23} height={25} {...props}>
    <g strokeWidth={2} fill="none" fillRule="evenodd">
      <path
        d="M8.136 15L2.02 18.474C1.387 18.832 1 19.477 1 20.172V24h21v-3.828c0-.696-.387-1.34-1.02-1.698L14.864 15"
        stroke="#8FA4AE"
        className="stroke"
      />
      <path
        d="M14 19h3"
        stroke="#CFD8DC"
        strokeLinecap="square"
        className="stroke"
      />
      <path
        d="M5 7.429C5 3.878 7.91 1 11.5 1S18 3.878 18 7.429C18 12.786 15.09 16 11.5 16S5 12.786 5 7.429z"
        stroke="#8FA4AE"
        strokeLinecap="square"
        className="stroke"
      />
      <path
        d="M6 6.706A6.203 6.203 0 0 0 7.817 7c1.798 0 3.42-.767 4.585-2A6.32 6.32 0 0 0 17 7"
        stroke="#CFD8DC"
        className="stroke"
      />
    </g>
  </svg>
);

export default MenuPrivateAgents;
