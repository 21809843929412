/* eslint-disable max-len */
import React from 'react';

const MenuLinks = props => (
  <svg
    width={30}
    height={32}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <linearGradient x1="0%" y1="0%" x2="100.565%" y2="99.579%" id="mnl_c">
        <stop stopColor="#0F4BAF" offset="0%" />
        <stop stopColor="#009FFF" offset="100%" />
      </linearGradient>
      <path
        d="M21 0H1a1 1 0 0 0-1 1v22a1 1 0 0 0 1 1h20a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1zm-6 16c0 2.206-1.794 4-4 4s-4-1.794-4-4v-3h2v3c0 1.103.897 2 2 2s2-.897 2-2v-3h2v3zm-5-1V9h2v6h-2zm5-4h-2V8c0-1.103-.897-2-2-2s-2 .897-2 2v3H7V8c0-2.206 1.794-4 4-4s4 1.794 4 4v3z"
        id="mnl_b"
      />
      <filter
        x="-31.8%"
        y="-20.8%"
        width="163.6%"
        height="158.3%"
        filterUnits="objectBoundingBox"
        id="mnl_a"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation={2}
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0.443137255 0 0 0 0 1 0 0 0 0.2 0"
          in="shadowBlurOuter1"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path d="M-1-2h32v32H-1z" />
      <g transform="translate(4 0)" fillRule="nonzero">
        <use fill="#000" filter="url(#mnl_a)" xlinkHref="#mnl_b" />
        <use fill="url(#mnl_c)" xlinkHref="#mnl_b" />
      </g>
    </g>
  </svg>
);

export default MenuLinks;
