import React from 'react';

const ChevronDown = props => (
  <svg width={12} height={7} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M-6-9h24v24H-6z" />
      <path
        d="M1.5 1.5L6 6l4.5-4.5"
        stroke="#FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
    </g>
  </svg>
);

export default ChevronDown;
