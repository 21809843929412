/* eslint-disable max-len */
import React from 'react';

const MenuSearch = props => (
  <svg
    width={32}
    height={32}
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <defs>
      <linearGradient x1="0%" y1="0%" x2="100.565%" y2="99.579%" id="a">
        <stop stopColor="#144391" offset="0%" />
        <stop stopColor="#4DB1ED" offset="100%" />
      </linearGradient>
      <linearGradient x1="0%" y1="0%" x2="100.565%" y2="99.579%" id="d">
        <stop stopColor="#0F4BAF" offset="0%" />
        <stop stopColor="#009FFF" offset="100%" />
      </linearGradient>
      <path
        d="M23 4H12.5L9.8.4A1 1 0 0 0 9 0H1a1 1 0 0 0-1 1v22a1 1 0 0 0 1 1h22a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1zm-6.707 15.707L12.886 16.3c-.693.437-1.507.7-2.386.7A4.505 4.505 0 0 1 6 12.5C6 10.019 8.019 8 10.5 8s4.5 2.019 4.5 4.5c0 .879-.263 1.693-.7 2.386l3.407 3.407-1.414 1.414z"
        id="c"
      />
      <filter
        x="-29.2%"
        y="-20.8%"
        width="158.3%"
        height="158.3%"
        filterUnits="objectBoundingBox"
        id="b"
      >
        <feOffset dy={2} in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          stdDeviation={2}
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
        />
        <feColorMatrix
          values="0 0 0 0 0 0 0 0 0 0.443137255 0 0 0 0 1 0 0 0 0.2 0"
          in="shadowBlurOuter1"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path d="M-1-2h32v32H-1z" />
      <g transform="translate(4 0)" fillRule="nonzero">
        <circle fill="url(#a)" cx={10.5} cy={12.5} r={2.5} />
        <use fill="#000" filter="url(#b)" xlinkHref="#c" />
        <use fill="url(#d)" fillRule="evenodd" xlinkHref="#c" />
      </g>
    </g>
  </svg>
);

export default MenuSearch;
