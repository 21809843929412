import React from 'react';
import PropTypes from 'prop-types';
import className from 'classnames';

import css from './Avatar.module.scss';
import EmptyAvatar from './empty.png';

const Avatar = props => {
  const { src, alt, lg, md, sm, xs, ...otherProps } = props;

  const source = src || EmptyAvatar;

  const classes = className({
    [css.avatar]: true,
    [css['avatar--lg']]: lg,
    [css['avatar--md']]: md,
    [css['avatar--sm']]: sm,
    [css['avatar--xs']]: xs,
  });

  return (
    <span
      title={alt}
      className={classes}
      {...otherProps}
      style={{ backgroundImage: `url(${source})` }}
    />
  );
};

Avatar.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string.isRequired,
  lg: PropTypes.bool,
  md: PropTypes.bool,
  sm: PropTypes.bool,
  xs: PropTypes.bool,
};
Avatar.defaultProps = {
  src: null,
  lg: false,
  md: false,
  sm: false,
  xs: false,
};

export default Avatar;
