import React from 'react';

const MenuReportHover = props => (
  <svg width={31} height={22} {...props}>
    <defs>
      <linearGradient
        x1="-9.42%"
        y1="36.79%"
        x2="111.973%"
        y2="77.417%"
        id="MenuReporting02Hover_svg__a"
      >
        <stop stopColor="#0F4BAF" offset="0%" />
        <stop stopColor="#009FFF" offset="100%" />
      </linearGradient>
      <linearGradient
        x1="89.147%"
        y1="73.201%"
        x2="0%"
        y2="24.251%"
        id="MenuReporting02Hover_svg__b"
      >
        <stop stopColor="#009FFF" offset="0%" />
        <stop stopColor="#0F4BAF" offset="100%" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        stroke="url(#MenuReporting02Hover_svg__a)"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3 3l10.5 9L24 3"
      />
      <path
        d="M26.5.5v10.563c2.391.546 3.697 2.465 3.697 4.548v.335c0 1.84-.93 3.145-2.447 3.145-.84 0-1.545-.39-1.709-.998h-.062c-.17.595-.813.991-1.572.991-1.182 0-1.92-.861-1.92-2.242v-.773c0-1.312.73-2.194 1.94-2.194.883 0 1.327.513 1.45.95h.055v-.854h1.298v3.404c0 .451.301.697.698.697.54 0 1.073-.67 1.073-2.215v-.198c0-2.003-1.477-3.678-3.924-3.678l-.282.005c-2.335.098-3.942 1.75-3.942 4.302v.26c0 2.919 1.934 4.197 4.074 4.197l.314-.003c.74-.02 1.32-.13 1.689-.29v1.072c-.349.158-1.033.288-2.01.288l-.282-.004c-1.859-.057-3.423-.844-4.286-2.307L.5 19.5V.5h26zm-1.662 14.469c-.554 0-.943.396-.943 1.292v.376c0 .95.382 1.312.957 1.312.526 0 .943-.307.943-1.23v-.568c0-.834-.444-1.182-.957-1.182zm-.338-4.042V2.5h-22v15l17.187.001a6.99 6.99 0 0 1-.058-.912v-.349c0-3.048 1.988-5.095 4.87-5.313z"
        fill="url(#MenuReporting02Hover_svg__b)"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default MenuReportHover;
