import React from 'react';
import { Route } from 'react-router-dom';

import Analytics from './Analytics';

const AnalyticsTracker = () => <Route component={Analytics} />;

AnalyticsTracker.propTypes = {};
AnalyticsTracker.defaultProps = {};

export default AnalyticsTracker;
