import { Permissions } from 'react-rg-iam';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import AuthInfo from '../../lib/HOC/AuthInfo';
import ExpansiveLoading from '../UI/ExpansiveLoading/ExpansiveLoading';
import React, { Suspense } from 'react';


const RouteCheckAcess = ({
  path,
  children,
  userCompaniesIds,
  user,
  resource,
  operation,
  permissionArguments,
  withSuspense,
  ...data
}) => {
  if (!user || !userCompaniesIds) return null;

  const hasAccess = Permissions.checkAccess(
    user._id,
    resource,
    operation,
    userCompaniesIds,
    permissionArguments
  );

  if (hasAccess && withSuspense) {
    return (
      <Suspense fallback={<ExpansiveLoading />}>
        <Route path={path} {...data}>{children}</Route>
      </Suspense>
    );
  }

  if (hasAccess) {
    return <Route path={path} {...data}>{children}</Route>;
  }

  return <Redirect 
    from={path}
    exact
    to={"/"}
  />;
};

RouteCheckAcess.propTypes = {
  path: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  operation: PropTypes.string.isRequired,
  permissionArguments: PropTypes.array
};

RouteCheckAcess.defaultProps = {};

export default RouteCheckAcess;
