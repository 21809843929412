/* eslint-disable max-len */
import React from 'react';

const MenuUsers = props => (
  <svg width={24} height={32} {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M-4-4h32v32H-4z" />
      <g transform="translate(1 1)" strokeLinecap="square" strokeWidth={2}>
        <path
          d="M18 18H4v-1.313c0-1.213.725-2.307 1.846-2.772C6.981 13.445 8.67 13 11 13s4.019.445 5.154.916A2.994 2.994 0 0 1 18 16.687V18z"
          stroke="#8FA4AE"
          className="stroke"
        />
        <circle stroke="#8FA4AE" className="stroke" cx={11} cy={6} r={4} />
        <path
          stroke="#CFD8DC"
          className="stroke"
          d="M0 4V0h4M22 4V0h-4M0 18v4h4M22 18v4h-4"
        />
      </g>
    </g>
  </svg>
);

export default MenuUsers;
